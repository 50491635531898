<template>
  <v-app id="inspire">
    <v-app-bar flat color="primary">
      <v-container class="mx-auto d-flex align-center justify-center">
        <v-img
          alt="DevCentre logo"
          src="@/assets/devcentrelogo.png"
          class="mr-5"
          background-color="primary"
          max-width="60"
        />

        <router-link to="/" class="devcentre-title">
          <h1>{{ title }}</h1>
        </router-link>

        <v-spacer></v-spacer>
        <v-btn
          v-for="link in links"
          :key="link"
          :text="link.label"
          :to="link.uri"
          variant="text"
        ></v-btn>
      </v-container>
    </v-app-bar>

    <v-main class="bg-grey-lighten-3" fill-height>
      <!-- Use the v-container directly inside v-main with the "fill-height" property -->
      <v-container fill-height class="align-center justify-center" fluid>
        <!-- Wrap the content in a flex row that centers items vertically and horizontally -->
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="8">
            <!-- Adjusted for centering -->
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer color="#2ab3b8" style="max-height: 60px" padless>
      <v-container fill-height class="align-center justify-center" fluid>
        <v-btn text="About" variant="text" to="/about"></v-btn>
        <v-btn text="Contact" variant="text" to="/contact"></v-btn>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    title: "DevCentre",
    logo_url: "@/assets/devcentrelogo.png",
    links: [
      { label: "Solutions", uri: "/solutions" },
      { label: "Services", uri: "/services" },
      { label: "Examples", uri: "/examples" },
      { label: "Assistant", uri: "/assistant" },
      { label: "Contact", uri: "/contact" },
      { label: "About", uri: "/about" },
    ],
  }),
  methods: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(0, 10, 69);
}

#nav-bar {
  background-color: #2ab3b8;
  color: white;
  padding: 50px;
}

nav {
  padding: 50px;
  background-color: #2ab3b8;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.v-text-field.solo-inverted {
  background-color: #333; /* Dark background color */
  border-radius: 4px; /* Slight rounding of corners */
  color: white; /* Text color */
}

.v-text-field.solo-inverted::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Lighter placeholder text */
}

.v-text-field .v-icon {
  color: white; /* Icon color */
}

.v-carousel-item {
  position: relative;
}

.devcentre-title {
  text-decoration: none;
  color: white;
}

.devcentre-intro {
  font-size: 80px;
  text-align: left;
  color: #228185;
  line-height: 1;
}

.devcentre-subintro {
  font-size: 30px;
  font-style: italic;
  text-align: left;
  color: #2ab3b8;
}

.devcentre-text {
  font-size: 20px;
  text-align: left;
  color: #2ab3b8;
}
.rounded-image {
  border-radius: 20px;
}
</style>
