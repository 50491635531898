<template>
  <v-container>
    <h1 class="devcentre-intro">Easy to use Software Services</h1>
    <p class="devcentre-subintro">
      DevCentre offers our range of Software Services to support Business
      Applications. They are are are designed to help you build and deliver
      quality applications faster and more efficiently with no coding required.
    </p>
    <br />

    <br />

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">API Designer and Platform</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            Our API Designer makes developing new data APIs a breeze. No coding
            required, and instantly available. The API Platform provides a
            scalable, and flexible way to connect to your data. It is hosted and
            managed by us, so you don't need to worry about infrastructure.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/api_sensei.jpg"
            alt="API Designer"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Integration Designer and Platform</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            Use our drag and drop component based Integration Designer to build
            integrations between your business systems. Take advantage of the
            broad array of connectors, translators and processors to build your
            integrations. Then deploy them to our Integration Platform for a
            scalable, and flexible way to run your integrations.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/integration.jpg"
            alt="Integration Platform"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Kanban Process Automation</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            Our Kanban based Process Automation helps you automate your business
            by first creating a manual Kanban board that captures the flow of
            work. Then you can introduce automation over time in a adaptive way.
            Automation is specified by business rules which can operate both
            within the board, and reach out to other systems using integration
            plugins. Our process engine runs on a cluster to ensure high
            availability.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/kanban.jpg"
            alt="Process Automation"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Visualization Driven Insights</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            Use our Visualization Builder to build dashboards and reports to
            help help you understand extract business insights from your
            business data. Specify the data sources, and then specify the and
            aggregation rules to build your visualizations. Choose from a range
            of different visualization components to build your dashboards.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/visualization.jpg"
            alt="Visualization Driven Insights"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Assistant Builder</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            Let our Assistant Builder help you build your very own online
            artificial intelligent assistant. Use it to engage with your
            prospects earlier in a way to help them understand your products and
            services.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/ai_assistant.jpg"
            width="80%"
            class="rounded-image"
            alt="Assistant Builder"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script></script>
