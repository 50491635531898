import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SolutionsView from "../views/SolutionsView.vue";
import ServicesView from "../views/ServicesView.vue";
import ExamplesView from "../views/ExamplesView.vue";
import IntegrationView from "../views/IntegrationView.vue";
import VisualizationView from "../views/VisualizationView.vue";
import AssistantView from "../views/AssistantView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/solutions",
    name: "solutions",
    component: SolutionsView,
  },
  {
    path: "/examples",
    name: "examples",
    component: ExamplesView,
  },
  {
    path: "/integration",
    name: "integration",
    component: IntegrationView,
  },
  {
    path: "/visualization",
    name: "visualization",
    component: VisualizationView,
  },
  {
    path: "/assistant",
    name: "assistant",
    component: AssistantView,
  },
  {
    path: "/services",
    name: "services",
    component: ServicesView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/sent",
    name: "sent",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactSent.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
