<template>
  <v-container>
    <h1 class="devcentre-intro">Example Applications</h1>
    <p class="devcentre-subintro">
      Here are some examples of applications that have be built using DevCentre.
    </p>
    <br />

    <br />

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">
        Internet Service Provider Customer Provisioning
      </p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            Internet Service Provider required software to provisioning
            customers onto their network and to physically connect them to the
            Fibre network. This is a complex process involving multiple
            partners.
          </p>
          <p class="devcentre-text ma-3">
            Process Automation orchestrated the provisioning of services for the
            customer. This included managing the installation of the Fibre
            connection through a partner organisation, supply and delivery of
            modems through a hardware supplier, and creation of billing accounts
            accounts in the accounting system.
          </p>
          <p class="devcentre-text ma-3">
            The Visualization Builder was used to create dashboards to report on
            the status of customer provisioning, and to provide service quality
            metrics.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/isp_datacentre.jpg"
            alt="Internet Service Provider Customer Provisioning"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Clothing Sales B2B Integration</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            Clothing Sales Company required a system to integrate between their
            ecommerce system and the wharehousing and distribution system.
            Orders from the ecommerce system needed to be sent to the warehouse
            for pick and pack.
          </p>
          <p class="devcentre-text ma-3">
            Integration Platform was used to build the integration between the
            ecommerce system and the warehouse system. The integration was
            designed to regularly poll the ecommerce system for new orders and
            batch them for delivery to the wharehousing system.
          </p>
          <p class="devcentre-text ma-3">
            Another integration was built to send the status of the orders back
            to the ecommerce system so that customers could be informed of the
            status of their orders.
          </p>
          <p class="devcentre-text ma-3">
            A final integration was built to update the ecommerce system with
            stock levels from the warehouse.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/clothing.png"
            alt="Clothing Sales B2B Integration"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Executive Metrics Dashboard</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            A multinational company wanted to centralise the reporting of key
            metrics. This would require drawing data from multiple systems, each
            with their own data formats and access restrictions.
          </p>
          <p class="devcentre-text ma-3">
            Integration Platform was used to build the integrations between the
            different systems, to centralise the data, and to then aggregate the
            data into a form that could be easily cosumed by the Visualizations.
            A dashboard was built using the Visualization Builder to display the
            metrics during executive meetings. The dashboard was designed to be
            displayed on a large screen in the boardroom.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/cisco_frontage_small.jpg"
            alt="Executive Metrics Dashboard"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Software Company Analyst Assistant</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            A software company wanted to provide a assistant on their website to
            help customers develop requirements. The assistant would ask
            questions to understand the customer's needs and then guide them to
            provide contact information to be contacted by a consultant.
          </p>
          <p class="devcentre-text ma-3">
            The Assistant Builder was used to build the assistant. The assistant
            was designed to be able to understand the customer's requirements
            and to guide them to provide the information required to be
            contacted by a consultant.
          </p></v-col
        >

        <v-col cols="6">
          <img
            src="/images/screens.jpg"
            width="80%"
            class="rounded-image"
            alt="Assistant Builder"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script></script>
