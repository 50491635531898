<template>
  <v-card style="border: 2px solid #2ab3b8">
    <v-card-text
      class="chatbox"
      max-height="400"
      style="height: 400px; overflow-y: auto"
    >
      <img
        src="./images/ai_assistant.jpg"
        width="20%"
        class="rounded-image image-margin"
      />
      <div
        v-for="(message, index) in messages"
        :key="index"
        :class="message.user ? 'user-message' : 'assistant-message'"
      >
        <v-card
          variant="tonal"
          class="mb-2 message-box pa-2"
          v-html="message.text"
        >
        </v-card>
      </div>
      <v-progress-circular
        v-if="loading"
        indeterminate
        size="24"
      ></v-progress-circular>
    </v-card-text>
    <v-card-actions class="px-2">
      <v-text-field
        v-model="newMessage"
        :placeholder="prompt_text"
        solo
        rounded
        color="grey lighten-2"
        append-icon=""
        class="no-border"
        @keyup.enter="sendMessage"
        :disabled="!prompt_active"
      >
        <template v-slot:append>
          <v-btn @click="sendMessage" :disabled="!prompt_active"
            >SEND
            <v-icon size="32" color="primary">mdi-send-circle</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-card-actions>
  </v-card>
</template>

<script>
import APIService from "@/services/APIService";
import { marked } from "marked";

export default {
  name: "App",
  components: {},
  data: () => ({
    newMessage: "",
    session_id: "",
    messages: [],
    message_count: 0,
    prompt_text: "Describe requirements?",
    prompt_active: true,
    loading: false,
  }),
  methods: {
    async sendMessage() {
      const message = this.newMessage.trim();

      if (message === "") return;

      this.loading = true;

      this.message_count++;

      this.newMessage = "";
      this.messages.push({ user: true, text: message });
      this.$nextTick(() => {
        const chatbox = this.$el.querySelector(".chatbox");
        chatbox.scrollTop = chatbox.scrollHeight;
      });

      if (this.message_count > 6) {
        if (message.includes("@") && message.includes(".")) {
          this.messages.push({
            user: false,
            text: "<br /><h2>Thank you for providing your email address. We will contact you shortly.</h2>",
          });

          const to_send = JSON.stringify(this.messages);

          APIService.sns(`New Discussion: ${message}`, to_send).then(
            (response) => {
              console.log(response);
            }
          );
          this.prompt_active = false;
        } else {
          this.messages.push({
            user: false,
            text: "<br /><h2>If you wish to receive further assistance please provide a valid email address.</h2>",
          });
        }
        this.$nextTick(() => {
          const chatbox = this.$el.querySelector(".chatbox");
          chatbox.scrollTop = chatbox.scrollHeight;
          this.loading = false;
        });
        return;
      }

      if (this.session_id === "") {
        const response = await APIService.start();
        this.session_id = response.data.session_id;
      }

      APIService.prompt(this.session_id, message).then((response) => {
        var text_to_push = marked(response.data.text);
        if (this.message_count == 6) {
          this.prompt_text = "Please provide Email Address?";
          text_to_push =
            text_to_push +
            "<br /><h2>I hope this discussion was useful. For further assistance please provide your email address for us to contact you.</h2>";
        }

        this.messages.push({ user: false, text: text_to_push });
        this.$nextTick(() => {
          const chatbox = this.$el.querySelector(".chatbox");
          chatbox.scrollTop = chatbox.scrollHeight;
          this.loading = false;
        });
      });
    },
  },
};
</script>

<style>
.user-message {
  color: rgb(50, 160, 92);
  display: flex;
  justify-content: flex-end;
}

.assistant-message {
  color: rgb(41, 134, 127);
  display: flex;
  justify-content: flex-start;
}

.no-border .v-input__control {
  border-bottom: none !important;
}

.message-box {
  display: inline-block;
  padding: 10px;
  margin: 10px;
  white-space: normal;
  word-wrap: break-word;
  height: auto;
  max-width: 80%;
  text-align: left;
}

.image-margin {
  margin: 10px;
  align-self: flex-start;
}
</style>
