<template>
  <v-container>
    <h1 class="devcentre-intro">Business Requirements Assistant</h1>
    <p class="devcentre-text">
      Our AI Business Requirements Assistant is here to discuss your
      requirements and give you ideas about potential features. Describe your
      requirements in the chat box below to begin your consultation with out AI
      Assistant. Once your requirements are defined we can discuss the next
      steps to build your application.
    </p>
    <br />

    <AIAssistant></AIAssistant>
  </v-container>
</template>

<script>
import AIAssistant from "@/components/AIAssistant";
export default {
  name: "AssistantView",
  components: {
    AIAssistant,
  },
};
</script>
