<template>
  <v-container>
    <h1 class="devcentre-intro">Building Adaptable, Robust Solutions</h1>
    <p class="devcentre-subintro">
      DevCentre can provide end to end services for developing your business
      solutions. We leverage our tools and software to minimize development time
      and complexity, and maximize the value to your business.
    </p>
    <br />

    <br />

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Requirements Gathering</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            Our requirements gathering begins on this web site, with
            propspective clients able to describe their needs to our AI
            assistant. From there one of our consultants will work with them to
            understand their unique business challenges. We take the time to
            deeply understand the unique business challenges, goals, and
            requirements. We will will work closely with the client to define a
            clear, comprehensive project scope.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/user_and_professional.jpg"
            alt="Requirements Gathering"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Architecture and Design</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            Having gathered requirements we will design a solution that meets
            the clients needs using our tools and services. Our key
            architectural principle is to avoid reinventing the wheel,
            leveraging our software services and tools to minimize development
            time and complexity. This approach has the benefit of minimizing
            development time and ensuring security, scalability and quality.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/architecture.jpg"
            alt="Architecture and Design"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Accelerated Development</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            Where possible we will use our tools and services to accelerate
            development without writing code. Where custom development is
            required our team will use agile methodologies and test driven
            development to ensure high quality is maintained. We'll keep you
            engaged and informed throughout the process, with regular demos and
            opportunities for feedback.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/developers.jpg"
            alt="Accelerated Development"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Deployment and Optimization</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            There are broadly two options for deployment. We can host your
            solution on our infrastructure, which is managed by our team. This
            will provide high availablity and reliability as we monitor our own
            environment. Alternatively we deploy to your preferred environment.
            This might include popular cloud platforms such as AWS, GCP. There
            can also be hybrid options where a web site is hosted hosted
            elsewhere, but use our API for some functionality.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/cloud.jpg"
            alt="Deployment and Optimization"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card style="border: 2px solid #2ab3b8" class="mb-3">
      <p class="devcentre-subintro ma-3">Ongoing Support</p>
      <v-row align="center" justify="right">
        <v-col cols="6">
          <p class="devcentre-text ma-3">
            We offer flexible support options to keep your solution running
            smoothly, as well as ongoing enhancements to adapt to your evolving
            needs. Our services are clustered and highly redundant, ensuring
            your software does not experience outages. We also offer ongoing
            call support to ensure your business is never left in the lurch.
          </p>
        </v-col>
        <v-col cols="6">
          <img
            src="/images/call_center.jpg"
            alt="Ongoing Support"
            width="80%"
            class="rounded-image"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script></script>
