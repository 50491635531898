<template>
  <v-container>
    <h1 class="devcentre-intro">Bringing People and Machines Together</h1>
    <p class="devcentre-subintro">
      We Empower organizations to innovate by integrating flexible human skills
      with the precision and scalability of machines. We utilize our expertise
      in AI, automation, integration, and visualization to help you achieve your
      business goals.
    </p>
    <br />

    <v-btn color="primary" dark large to="/assistant" class="devcentre-button"
      >Talk to AI Solution Assistant</v-btn
    >

    <br />
    <br />
    <v-row align="center" justify="right">
      <v-col cols="12" sm="6" md="4" offset="2">
        <p class="devcentre-subintro">
          Our experienced experts can tackle your unique business needs with our
          adaptable suite of technologies.
        </p>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <img
          src="/images/user_and_professional.jpg"
          alt="User and Professional"
          width="500px"
          class="rounded-image"
        />
      </v-col>
    </v-row>

    <v-row align="center" justify="left">
      <v-col cols="12" sm="6" md="4">
        <img
          src="/images/real_world_site.jpg"
          alt="Automation Services"
          width="500px"
          class="rounded-image"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" offset="3">
        <p class="devcentre-subintro">
          Enhance efficiency, reduce errors, and save time with our cutting-edge
          automation services.
        </p>
      </v-col>
    </v-row>

    <v-row align="center" justify="right">
      <v-col cols="12" sm="6" md="4" offset="2">
        <p class="devcentre-subintro">
          Integrate your platforms effortlessly, ensuring data flows smoothly
          across your organization.
        </p>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <img
          src="/images/integration.jpg"
          alt="Integration Services"
          width="500px"
          class="rounded-image"
        />
      </v-col>
    </v-row>

    <v-row align="center" justify="left">
      <v-col cols="12" sm="6" md="4">
        <img
          src="/images/visualization.jpg"
          alt="Visualization Services"
          width="500px"
          class="rounded-image"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" offset="3">
        <p class="devcentre-subintro">
          Our visualization expertise makes complex information understandable
          and decision-making clearer.
        </p>
      </v-col>
    </v-row>

    <v-row align="center" justify="left">
      <v-col cols="12" sm="6" md="4" offset="2">
        <p class="devcentre-subintro">
          Empower your business with AI assistants that offer personalized
          support and streamline operations around the clock.
        </p>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <img
          src="/images/ai_assistant.jpg"
          alt="AI Assistants"
          width="500px"
          class="rounded-image"
        />
      </v-col>
    </v-row>

    <br />

    <v-btn color="primary" dark large to="/assistant" class="devcentre-button"
      >Talk to AI Solution Assistant</v-btn
    >
    <v-btn
      color="primary"
      dark
      large
      to="/contact"
      class="devcentre-button ml-2"
      >Request Human Help</v-btn
    >
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  data: () => ({}),
};
</script>

<style>
.display-3 {
  color: white;
  font-size: 2rem;
  position: absolute; /* Position it over the image */
  bottom: 10%; /* Position from the bottom of the image */
  left: 50%;
  transform: translateX(-50%); /* Center the text horizontally */
  white-space: nowrap; /* Ensure the text stays on a single line */
  background-color: rgba(0, 0, 0, 0.5); /* black with 50% opacity */
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
}

.carousel {
  width: 90%; /* Adjust this value as needed */
  margin: auto;
}
</style>
